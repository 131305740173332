/* Case Studies Container */
.case-studies-container {
  position: relative;
  display: flex;
  flex-direction: column; /* Stacked vertically */
  justify-content: flex-start;
  align-items: flex-start; /* Align children to the left */
  width: 100%;
  padding: 20px;
  margin: 0;
  color: #0c453a !important;  /* Force all text to be #0c453a */
  box-sizing: border-box;
  background: #F1F0F6;  /* White background for the main section */
  min-height: 100vh;
  transition: min-height 0.3s ease-in-out;
  margin-top: 0; /* Ensure no top margin here */
}

/* Case Study Title (Section Heading) */
.case-study-title {
  font-size: 48px; /* Larger font size */
  font-weight: 700; /* Bold title */
  margin-bottom: 20px;
  color: #02373D;  /* Updated color to match services title */
  padding-top: 20px;
  padding-left: 20px; /* Ensure the title aligns left by adding some margin */
  transition: none;
  text-align: left; /* Force left alignment */
}

/* Case Study Item */
.case-study-item {
  width: calc(100% - 60px); /* Adjust width to accommodate both left and right margins */
  margin: 20px auto;
  background-color: #00555A;  /* Dark green background for boxes */
  border: 1px solid #00555A;  /* Dark green border */
  border-radius: 8px;
  padding: 10px 20px;
  cursor: default; /* Change cursor to default */
  display: flex;
  flex-direction: row; /* Two columns: left (text) and right (image) */
  justify-content: space-between;
  align-items: center;
  gap: 10px;  /* Moderate gap between text and image */
  min-height: 250px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  margin-left: 20px;  /* Add left margin to align with title */
  margin-right: 20px; /* Add right margin for balanced padding */
}

/* Case Study Left Section (Text) */
.case-study-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Title at top, description at bottom */
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Case Study Item Title (h2) */
.case-study-title-text {
  font-size: 20px !important;
  font-weight: 700; /* Bold title */
  color: white !important;  /* White text color for the title */
  text-align: left;
  margin: 0;
  margin-bottom: 30px; /* Increased gap between title and paragraph */
  transition: none;
}

/* Case Study Description (Paragraph Text) */
.case-study-description {
  margin: 0;
  margin-top: auto; /* Push description to the bottom */
  font-size: 16px;
  line-height: 1.5;
  color: white !important;  /* White text for description */
  font-weight: 500;
}

/* Case Study Right Section (Image Container) */
.case-study-right {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: 10px;  /* Extra spacing on right */
}

/* Image Styling */
.case-study-image {
  width: 150px;   /* Base size for small screens */
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
}

/* Responsive Adjustments for Small Screens */
@media (max-width: 768px) {
  .case-studies-container {
    padding: 10px;
  }

  .case-study-item {
    width: 90%;
    margin: 20px auto;
    padding: 15px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .case-study-title-text {
    font-size: 24px !important;
    margin-bottom: 10px !important;
  }

  .case-study-image {
    width: 150px;
    height: 150px;
  }

  .case-study-left {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* Center-align title on small screens */
  .case-study-title {
    text-align: center;
    padding-left: 0; /* Remove left padding on small screens */
  }
}

/* Responsive Adjustments for Small Screens */
@media (max-width: 768px) {
  .case-studies-container {
    padding: 10px;
  }

  .case-study-item {
    width: 100%; /* Ensure full width */
    margin: 20px auto;
    padding: 15px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .case-study-title-text {
    font-size: 24px !important;
    margin-bottom: 10px !important;
  }

  .case-study-image {
    width: 100%; /* Full width of container */
    max-width: 250px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Prevent distortion */
  }

  .case-study-left {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* Make sure the image is allowed to resize */
  .case-study-right {
    flex: 1 1 auto; /* Let the image grow */
  }

  /* Center-align title on small screens */
  .case-study-title {
    text-align: center;
    padding-left: 0;
  }
}