/* Full-page blob background */
.blob-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Background layer */
  background: linear-gradient(to top, #702de3, #f5632f); /* Blob gradient */
  background-size: cover; /* Covers entire page */
  overflow: hidden; /* Prevents overflow */
}

/* Global font styles */
body {
  font-family: 'Inclusive Sans', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #f8f9fa; /* Light background for contrast */
  margin: 0;
  padding: 0;
}

/* App container to handle height and overflow */
.App {
  height: 100vh;
  overflow-y: scroll;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Inclusive Sans', Arial, sans-serif !important;
  font-weight: 700;
  margin: 0 0 1rem;
  color: #ffffff; /* White text for headings */
}

/* Case Study Title (for individual case study items) */
.case-study-title-text {
  font-size: 32px; /* Default font size for larger screens */
  font-weight: bold;
  color: white;
  text-align: left;
  margin-bottom: 15px;
  transition: font-size 0.3s ease, color 0.3s ease;
}

/* Case Study Subheading */
.case-study-subheading {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  font-weight: 400;
  transition: font-size 0.3s ease, color 0.3s ease;
}

/* Paragraphs */
p {
  font-family: 'Inclusive Sans', Arial, sans-serif;
  font-weight: 300;
  margin: 0 0 1.5rem;
}

/* Links */
a {
  font-family: 'Inclusive Sans', Arial, sans-serif;
  color: #ffffff; /* Vibrant white text for links */
  text-decoration: none;
  font-weight: 400;
  transition: color 0.2s ease;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Case Study Title Text: Make it smaller on small screens */
  .case-study-title-text {
    font-size: 20px !important; /* Reduce font size for smaller screens */
    margin-bottom: 10px !important; /* Adjust spacing below the title */
  }

  /* Case Study Subheading: Make it smaller on small screens */
  .case-study-subheading {
    font-size: 14px !important; /* Reduce font size for subheading on smaller screens */
  }

  /* Case Study Item Layout */
  .case-study-item {
    flex-direction: column;
    width: 90%;
    margin: 15px auto;
    min-height: 300px;
  }

  /* Blob Background (adjust if needed on small screens) */
  .blob-container {
    background-size: 100% 100%;
  }

  /* Adjust Image Sizes on Small Screens */
  .case-study-image {
    max-width: 120px;
    max-height: 120px;
  }
}

/* Apply background to the entire webpage */
html, body {
  background-color: #F1F0F6; /* Off-white background */
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  overflow-x: hidden; /* Prevent horizontal scroll */
}
