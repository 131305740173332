/* Main Contact Section */
.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align everything to the left */
  padding: 10px 20px; /* Set left and right padding to 20px */
  background: #F1F0F6;
  position: relative;
  min-height: 100vh;
}

/* Left Column: Contact Options */
.contact-options-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align with the left edge */
  width: 100%;
  padding-left: 0px; /* Align contact options with padding on the left */
}

/* Contact Options */
.contact-options {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the spacing between boxes */
  margin-top: 10px;
  width: 100%;
  padding-left: 20px; /* Align options left with padding */
}

/* Right Column: Form Container */
.contact-us-container {
  flex: 1;
  width: 100%;
  max-width: none;
  align-self: flex-start;
  padding-left: 30px; /* Align form container with padding on left */
}

.contact-us-header h1 {
  font-size: 48px; /* Increased title size */
  color: #0c453a;
  margin: 0;
  padding-left: 20px;
}

.contact-us-header h3 {
  font-size: 24px;
  color: #0c453a;
  margin: 10px 0 20px 0;
  padding-left: 20px;
}

/* Wrapper for Main Content (Contact Options & Form) */
.contact-us-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 20px; /* Reduce the gap between boxes */
  box-sizing: border-box;
}

/* Left Column: Contact Options */
.contact-options-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align with the left edge */
  width: 100%;
  padding-left: -20px;
}

/* Contact Options */
.contact-options {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the spacing between boxes */
  margin-top: 10px;
  width: 100%;
}

/* Each Contact Option Box */
.contact-option {
  background-color: #cac4ef;
  color: rgb(0, 0, 0);
  padding: 15px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 400px; /* Increased width for the boxes */
  height: 60px;
}

.contact-option:hover {
  transform: scale(1.05);
}

/* Icon inside the Contact Option */
.option-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
}

/* Text inside the Contact Option */
.option-text {
  display: flex;
  flex-direction: column;
}

/* Option Title */
.option-title {
  display: block;
  font-size: 16px;
}

/* Option Detail */
.option-detail {
  display: block;
  font-size: 14px;
  font-weight: normal;
}

/* Right Column: Form Container */
.contact-us-container {
  flex: 1;
  width: 100%;
  max-width: none;
  align-self: flex-start;
}

/* Form Fields */
.contact-us-form label {
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-bottom: 8px;
  display: block;
  text-align: left;
}

.contact-us-form input,
.contact-us-form textarea {
  width: 100%;
  padding: 12px 0;
  border: none;
  border-bottom: 2px solid #b0b0b0; /* Lighter gray border */
  font-size: 16px;
  background-color: transparent;
  color: black;
  margin-bottom: 20px;
  outline: none;
  caret-color: black;
  text-indent: 0;
  text-align: left;
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder {
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
}

/* Submit Button */
.contact-us-form button {
  background-color: #0c453a;
  color: white;
  border: 2px solid #0c453a;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contact-us-form button:hover {
  transform: scale(1.05);
  background-color: #0c453a;
  color: white;
}

.contact-us, 
.contact-us * {
  font-family: 'Inclusive Sans', sans-serif !important;
}

/* Footer Image */
.footer-image {
  width: calc(100% - 40px); /* Same as header image, with padding on both sides */
  max-height: 150px; /* Limit the max height */
  object-fit: cover; /* Ensures the image covers the container while cropping */
  margin: 20px auto 0; /* Centers the image and adds top margin */
  border-radius: 15px; /* Apply rounding to all corners */
  overflow: hidden; /* Prevents overflow */
}

/* Thank You Message */
.thank-you-message h4 {
  color: #02373D; /* Dark green color for the message */
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  /* Ensure the contact us section stays within the screen */
  .contact-us {
    padding-left: 0px; /* Keep left padding consistent */
    padding-right: 20px; /* Ensure there's padding on the right side too */
  }

  /* Adjust the contact us wrapper for smaller screens */
  .contact-us-wrapper {
    flex-direction: column; /* Stack the contact options and form vertically */
    align-items: flex-start; /* Align to the left */
    width: 100%; /* Ensure the wrapper takes full width */
    max-width: 100%; /* Prevent going off screen */
  }

  /* Contact Options align to the left and limit their width */
  .contact-options-container {
    align-items: flex-start;
    padding-left: 0px; /* Align to the left */
    width: 100%; /* Ensure contact options container fits within the screen */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
  }

  /* Contact Options: Limit the width of each option */
  .contact-option {
    width: 80%; /* Make the contact options take the full width */
    max-width: 80%; /* Prevent overflow */
  }

  /* Adjust the form container */
  .contact-us-container {
    width: 80%; /* Ensure the form container takes full width */
    max-width: 80%; /* Prevent overflow */
    padding-left: 20px; /* Align form fields with the left side */
  }

  /* Ensure the form inputs don't overflow */
  .contact-us-form input,
  .contact-us-form textarea {
    width: 100%; /* Ensure inputs are responsive */
    max-width: 100%; /* Prevent overflow */
    padding-left: 0px; /* Remove extra padding */
  }

  /* Adjust title padding for small screens */
  .contact-us-header h1 {
    font-size: 36px; /* Slightly smaller title for mobile */
    padding-left: 20px; /* Remove extra padding */
  }

  .contact-us-header h3 {
    font-size: 20px; /* Slightly smaller subheading for mobile */
    padding-left: 20px; /* Remove extra padding */
  }

  /* Add extra space below the form button to ensure it's in view */
  .contact-us-form button {
    margin-bottom: 50px; /* Add space below the button to ensure it's not cut off */
  }

  .footer-image-container {
    width: calc(100% - 40px); /* Adjust for padding on both sides (20px on each side) */
    margin: 10px 0; /* Adjust margins to ensure the image fits well */
    height: auto; /* Allow the height to adjust automatically */
    transform: scaleY(1.3); /* Scale image 30% taller */
    transform-origin: center center; /* Scale from the center */
    object-fit: cover; /* Ensures the image covers the space */
    padding-left: 20px; /* Apply 20px padding on the left */
    padding-right: 20px; /* Apply 20px padding on the right */
    border-radius: 15px; /* Rounded corners for the container */
    overflow: hidden; /* Prevent image overflow from the container */
  }
  
  .footer-image {
    width: 90%; /* Make the image fill the container */
    height: auto;
  }}