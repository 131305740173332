/* Services Wrapper */
.services-wrapper {
  padding: 50px 20px;
  background-color: #F1F0F6;
  text-align: left;
  box-sizing: border-box;
}

/* Services Title */
.services-title {
  font-size: 48px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #02373D;  /* Updated color */
  text-align: left;
  padding-left: 20px;
}

/* Services Container */
.services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px;  /* Equal gap horizontally and vertically */
  row-gap: 30px; /* Increase the vertical gap (space between rows) */
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 20px; /* Ensure no overflow on the right */
}

/* Service Card */
.service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: none;
  cursor: default; /* Change cursor to default */
  transition: transform 0.3s ease, background-color 0.3s ease;
  background-color: #00555A;
  color: #fff;
  text-align: left;
  min-height: 240px;  /* Increased min height */
  width: 95%;        /* Ensures it takes up available space */
  margin: 0;
  box-sizing: border-box; /* Ensure proper padding inside */
}

/* Hover effect */
.service-card:hover {
  transform: scale(1.05);
  box-shadow: none;
}

/* Active state */
.service-card.open {
  background-color: 00555A;
  transform: scale(1.05);
  box-shadow: none;
}

/* Title & Subtitle */
.service-card-title {
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}
.service-card-subtitle {
  font-size: 18px;
  margin: 10px 0;
  font-weight: 700;
  color: inherit;
}

/* Card content */
.service-card-content {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.5;
  color: inherit;
  font-weight: 500;
}

/* Small Screens (<=768px) => single column stacked */
@media (max-width: 768px) {
  .services-title {
    font-size: 36px;
    margin-bottom: 20px;
    margin-left: -20px; /* Add 10px margin to the left */
  }

  .service-card {
    padding: 15px;
    font-size: 14px;
    min-height: 100px;
    width: 100%; /* Set width to 100% to take up full available space */
    margin-left: 0; /* Remove left margin */
    margin-right: 0; /* Remove right margin */
    box-sizing: border-box; /* Ensure padding and border are included in width */
  }

  .service-card-title {
    font-size: 20px;
  }

  .service-card-subtitle {
    font-size: 16px;
  }

  .service-card-content {
    font-size: 14px;
  }

  /* Single column layout for small screens */
  .services-container {
    grid-template-columns: 1fr; /* Stack the cards */
    padding-left: 0; /* Adjust padding to ensure the boxes take full width */
    padding-right: 0; /* Adjust padding to ensure the boxes take full width */
  }
}