/* Page Wrapper remains centered for other sections */
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  align-items: center; /* Centers children by default */
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  background: #F1F0F6;
  padding-top: 80px; /* Adjusted padding-top to reduce space between nav and image */
}

/* Header Image */
.header-image {
  width: calc(100% - 40px); /* 20px padding on each side by default */
  max-height: 300px;
  object-fit: cover;
  margin: 0 auto 20px; /* Centers the image and adds bottom margin */
  border-radius: 15px; /* Apply rounding to all corners */
  overflow: hidden; /* Ensure no overflow from the rounded corners */
}

/* Home Container - override parent's centering */
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Left align content inside the container */
  align-self: flex-start;  /* Align the container itself to the left */
  width: 100%;
  max-width: 800px;
  padding: 20px;
  z-index: 2;
  text-align: left;
}

/* Title */
.home-container h1 {
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #02373D;
}

/* Subtitle */
.home-container h2 {
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 30px;
  color: #02373D;
}


/* Scroll Down Arrow */
.scroll-down-arrow {
  position: absolute;
  bottom: 20px;
  width: 30px;
  height: 30px;
  border-left: 4px solid black;
  border-top: 4px solid black;
  transform: translateX(-50%) rotate(225deg);
  animation: bounce 1.5s infinite ease-in-out;
}

/* Animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0) rotate(225deg);
  }
  50% {
    transform: translateY(10px) rotate(225deg);
  }
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .header-image {
    width: calc(100% - 10px); /* Only 5px padding on each side */
    margin: 0 0 20px; /* Remove horizontal auto margin to align left */
    height: auto; /* Allow the height to adjust automatically */
    transform: scaleY(1.3); /* Scale the image 30% taller */
    transform-origin: center center; /* Ensure it scales from the center */
    object-fit: cover; /* Ensures the image covers the space, cropping sides */
  }

  .home-container {
    padding-left: 10px;  /* Reduced left padding */
    padding-right: 10px; /* Reduced right padding */
  }

  .home-container h1 {
    font-size: 36px; /* Reduced font size for h1 on small screens */
    padding-left: 0px;  /* Reduced left padding */
    padding-right: 10px; /* Reduced right padding */
  }

  .home-container h2 {
    font-size: 20px; /* Reduced font size for h2 on small screens */
    padding-right: 10px; /* Reduced right padding */

  }

  /* Reduce space above the image */
  .page-wrapper {
    padding-top: 40px; /* Reduce padding above the image */
  }
}