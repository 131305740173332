/* Main About Section */
.about-section {
  margin-top: 0px; /* Ensure there's no margin at the top */
  padding: 30px 20px; /* Keep padding for internal spacing */
  background-color: #F1F0F6;
  border-radius: 10px;
  height: auto;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0; /* Remove any bottom margin that might cause a gap */
}

/* TOP: container for title + subheading, and image side-by-side on large screens */
.about-top {
  display: flex; /* Flexbox for side-by-side layout */
  justify-content: space-between; /* Space out the title and image */
  align-items: flex-start; /* Align items to the top */
  margin-bottom: 20px;
  position: relative; /* So that the absolute image positioning works */
}

/* Title + subheading container (left side) */
.about-top-left {
  margin-bottom: 20px;
}

.about-top-left h2 {
  font-size: 36px;
  color: #02373D; /* Color should match case studies */
  font-weight: bold;
  margin-bottom: 20px;
  padding-left: 0px; /* Add padding for alignment */
}

.about-top-left h3 {
  font-size: 28px;
  color: #02373D; /* Color should match case studies */
  font-weight: bold;
  margin-bottom: 15px;
  padding-left: 0px; /* Add padding for alignment */
}

.about-top-left p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 20px;
  padding-left: 0px; /* Reset padding to align to the left */
}

/* Image on the right side (fully right-aligned) */
.about-top-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

/* Image */
.about-image {
  display: block;
  width: 250px; /* Fixed width on large screens */
  border-radius: 10px;
  max-width: 100%;
  margin: 0;
}

/* BOTTOM: Three columns of text spanning entire container width */
.about-bottom {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 20px;
}

/* Each text column */
.about-col {
  flex: 1;
  margin-bottom: 20px;
}

.about-col h4 {
  font-size: 22px;
  font-weight: bold;
  color: #0c453a;
  margin-bottom: 10px;
}

.about-col p {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  font-weight: 400; /* Change from 600 to 400 for lighter weight */
  margin-bottom: 20px;
}

/* Small Screens (<=768px) => stacked layout */
@media (max-width: 768px) {
  .about-section {
    padding: 15px; /* Reduced padding for small screens */
  }

  .about-top-left h2 {
    font-size: 28px;
  }
  .about-top-left h3 {
    font-size: 24px;
  }

  .about-col p {
    font-size: 16px;
    line-height: 1.5;
  }

  .about-image {
    width: 150px;
    margin: 10px auto 20px; /* Move the image down and center it */
  }

  /* Hide the line breaks on small screens if you want */
  .br-spacer {
    display: none;
  }

  /* Stack columns on small screens */
  .about-bottom {
    flex-direction: column;
    gap: 15px; /* Reduced gap between columns on small screens */
  }

  /* Adjust margin bottom of each column */
  .about-col {
    margin-bottom: 10px; /* Reduced margin to minimize gap */
  }

  /* Adjust layout on small screens */
  .about-top {
    flex-direction: column; /* Stack title and image vertically */
    align-items: center; /* Center the content */
  }

  .about-top-right {
    margin-left: 0;
    padding-right: 0;
    margin-bottom: 20px; /* Ensure enough space between the image and bottom section */
  }

  .about-image {
    width: 200px; /* Adjust image size on small screens */
    margin: 10px auto 20px; /* Make sure there's space above and below */
  }
}

/* For very small screens (<=480px) */
@media (max-width: 480px) {
  .about-section {
    padding: 15px;
  }
  .about-top-left h2 {
    font-size: 24px;
  }
  .about-top-left h3 {
    font-size: 20px;
  }
  .about-col p {
    font-size: 14px;
    line-height: 1.4;
  }
}

/* Large screens (>=769px): side-by-side for top, full width for bottom */
@media (min-width: 769px) {
  .about-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .about-top-left {
    flex: 0 0 60%;
  }

  .about-top-right {
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding-right: 20px; /* Match case studies container's right padding */
  }

  .about-image {
    width: 250px;
  }

  /* The bottom row: three columns that span entire container width */
  .about-bottom {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 20px;
  }
  .about-col {
    flex: 1;
    margin-bottom: 0;
  }
}