/* Navigation bar styling */
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures all items are vertically centered */
  padding: 20px 40px 20px 20px;  /* Top, Right, Bottom, Left */
  background-color: #F1F0F6;  /* Solid off-white background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 90px; /* Consistent navbar height */
  box-sizing: border-box;
}

/* Logo styling */
.logo {
  font-size: 32px;
  font-weight: bold;
  color: #02373D;
  text-decoration: none;
  cursor: pointer;
  padding-left: 20px;  /* Default left padding for big screens */
}

/* Hamburger menu styling */
.hamburger {
  font-size: 24px;
  cursor: pointer;
  display: none; /* Hidden on large screens */
  padding: 10px;
  margin-right: 10px;
  align-self: center;
  background-color: #02373D; /* Background color for hamburger */
  color: #ffffff;           /* White hamburger icon */
  border-radius: 5px;
  box-sizing: border-box;
  height: 50px;             /* Better tap area */
  align-items: center;
  justify-content: center;
}

/* Navigation links container */
.nav-links {
  display: flex;
  align-items: center; /* Vertically center the links */
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
  height: 100%; /* Match navbar height */
}

/* Navigation links */
.nav-links a {
  display: flex; /* For vertical centering */
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 22px;
  color: #02373D;
  font-weight: 600;
  padding: 10px 15px;
  text-align: center;
  height: 40px; /* Consistent height */
  min-height: 40px; /* Prevents shrinking */
  transition: transform 0.2s ease;
}

/* Hover effect: scale up without changing color */
.nav-links a:hover {
  transform: scale(1.2);
  transform-origin: center;
}

/* Contact button styling */
.nav-links a.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00555A; /* Dark green background */
  color: white; /* White text */
  padding: 10px 20px;
  border-radius: 8px; /* Less rounded corners */
  border: 2px solid #00555A; /* Matching border color */
  font-weight: bold;
  height: 25px; /* Same height as other items */
  min-height: 20px;
  transition: transform 0.3s ease;
}

/* Hover effect for Contact button: scale up */
.nav-links a.contact-button:hover {
  transform: scale(1.05);
  background-color: #02373D; /* Keep the background green on hover */
  color: white; /* Keep text white */
}

/* For screens smaller than 900px, show hamburger menu and hide regular nav links */
@media (max-width: 1000px) {
  .nav-links {
    display: none; /* Hide regular nav links */
  }

  .hamburger {
    display: flex; /* Show hamburger icon */
  }
}

/* Dropdown for hamburger menu */
@media (max-width: 768px) {
  /* When dropdown is active */
  .nav-links.active {
    display: flex;
    flex-direction: column; /* Stack the menu items */
    background-color: #F1F0F6;  /* White background for the dropdown */
    position: absolute; /* Make sure it's positioned relative to the navbar */
    top: 90px; /* Just below the navbar */
    right: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: none;
    gap: 15px;
    z-index: 999;
    height: auto; /* Adjust height based on content */
  }

  /* For the dropdown, nav link text becomes black */
  .nav-links a {
    color: #000000;
  }

  /* Hamburger menu styling for small screens */
  .hamburger {
    display: flex;
    font-size: 24px;
    padding: 15px 10px 15px 15px;
    margin-right: 0;
    align-self: center;
    background-color: transparent; /* Background color for the hamburger */
    color: #02373D;           /* Hamburger icon in white */
    border-radius: 5px;
    box-sizing: border-box;
    height: 50px;             /* Better tap area */
    align-items: center;
    justify-content: center;
  }

  /* Adjust logo left padding on small screens */
  .logo {
    padding-left: 10px;  /* Reduced left padding */
  }
}

/* Navigation links (Hamburger Menu) - only visible when active */
.nav-links.active {
  display: flex;
  flex-direction: column;
  background-color: #F1F0F6;  /* White background for the dropdown */
  position: absolute;
  top: 90px; /* Dropdown appears just below the navbar */
  left: 0;
  right: 0;
  width: auto; /* Adjust width to fit the content */
  padding: 20px;
  box-shadow: none;
  gap: 15px;
  z-index: 999;
  border-radius: 8px; /* Optional rounded corners */
  height: auto; /* Adjust height based on the content */
  min-height: 160px; /* Ensure it has some minimum height */
}

/* For the dropdown, nav link text becomes black */
.nav-links a {
  color: #000000;
  padding: 10px 20px;  /* Adjust padding as needed to fit the size */
  font-size: 18px;
  text-align: center;
}