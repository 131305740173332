/* FAQ Container */
.faq-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0 !important;
  margin: 0 !important;
  color: #0c453a;
  background-color: #F1F0F6;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding-left: 50px; /* Keep left padding */
}

/* FAQ Title */
.faq-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  color: #0c453a;
  padding-top: 0 !important;
  padding-left: 40px;
}

/* FAQ Items */
.faq-item {
  position: relative;
  background-color: #F1F0F6;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.4s ease;
  color: #0c453a;
  text-align: left;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 80px;
  margin-left: 0; /* Ensure no extra margin */
  padding-left: 20px; /* Ensure no extra padding */
}

/* Separator Line */
.faq-item:not(:last-child)::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 40px; /* Keep the left padding for the line */
  width: calc(100% - 50px); /* Extend to the right */
  height: 1px;
  background-color: #0c453a;
  border-radius: 0;
}

/* Pseudo-element for plus/minus icon */
.faq-item::after {
  content: "+";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: bold;
  color: #0c453a;
}

/* When FAQ item is open, change plus to minus */
.faq-item.open::after {
  content: "-";
}

/* Opened FAQ */
.faq-item.open {
  height: auto;
  padding: 30px;
  background-color: #F1F0F6;
  color: #0c453a;
  align-items: flex-start;
}

/* Question Styling */
.faq-question {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.6;
  color: #0c453a;
  margin: 0;
  text-align: left;
  padding-left: 20px; /* 50px padding to align with line */
  margin-left: 0; /* Remove extra margin */
}

/* Answer Styling */
.faq-answer {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  margin-top: 10px;
  color: black !important;
  display: none;
  text-align: left;
  padding-left: 20px;
  margin-left: 0;
}

.faq-item.open .faq-answer {
  display: block;
  opacity: 1;
  color: black !important;
}

/* Responsive Adjustments for Medium Screens */
@media (max-width: 1024px) {
  .faq-container {
    padding: 30px 0;
  }
  .faq-item {
    padding: 20px;
  }
  .faq-question {
    font-size: 22px;
  }
  .faq-answer {
    font-size: 20px;
    font-weight: 500;
  }
  .faq-item:not(:last-child)::before {
    left: 20px;
    width: calc(100% - 20px);
  }
}

/* Responsive Adjustments for Small Screens */
@media (max-width: 768px) {
  .faq-container {
    padding: 20px 0; /* Reduce top and bottom padding */
    margin: 0 !important;
  }

  .faq-item {
    margin: 10px auto; /* Center items */
    padding: 15px 10px; /* 10px padding on left and right */
    width: calc(100% - 20px); /* Ensure full width minus padding */
    min-height: 100px; /* Ensure enough height for proper centering */
    box-sizing: border-box; /* Account for padding in width */
  }

  /* FAQ title adjustments (no change to padding) */
  .faq-title {
    font-size: 48px; /* Keep large font size */
    font-weight: bold;
    margin-bottom: 10px;
    color: #0c453a;
    text-align: left; /* Align title to the left */
    padding-left: 20px; /* Keep existing padding */
  }

  /* Ensure question aligns with answer (20px padding) */
  .faq-question {
    font-size: 20px;
    white-space: normal; /* Allow text to wrap */
    line-height: 1.5;
    text-align: left;
    margin-left: 0; /* Remove extra margin on left */
    padding-left: 0px; /* Add 20px padding to match the answer */
    padding-right: 30px; /* Ensure space for the plus sign */
  }

  /* Ensure the answer aligns with the question */
  .faq-answer {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    padding-left: 0px; /* Align answer with question */
  }

  /* Adjust separator line: for small screens */
  .faq-item:not(:last-child)::before {
    left: 20px; /* Adjust to match padding */
    width: calc(100% - 40px); /* Extend line across full width with 20px padding on each side */
  }

  /* Pseudo-element for plus/minus icon */
  .faq-item::after {
    content: "+"; /* Default plus sign */
    position: absolute;
    right: 10px; /* Adjust to keep the plus sign within bounds */
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: bold;
    color: #0c453a;
  }
}